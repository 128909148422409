<template>
<div class="text-center">
    <v-container>
        <h1 style="font-size: 20rem">404</h1>
    </v-container>
    <h1 style="font-weight:800">الصفحة المطلوبة غير موجودة</h1><br>
    <v-btn @click="goBack()" color="primary" large="">
        <h3>رجوع</h3>
        <v-icon class="mr-2">keyboard_arrow_left</v-icon>
    </v-btn>
</div>
</template>

<script>
export default {
    methods: {
        goBack() {
            window.history.go(-1)
        },
    }
}
</script>
